@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

@layer base {
  html {
    font-family: "Quicksand", sans-serif;
  }
}

input, select, button {
  @apply outline-none;
}

 body {
  @layer @apply m-0, p-0;
}

.pr-navlink-style {
  @apply flex items-center px-6 py-3 space-x-2 transition-all duration-200 ease-in-out hover:bg-gray-200;
}

.pr-navlink-style-collapsed {
  @apply flex items-center justify-center px-6 py-3 space-x-2 text-xl transition-all duration-200 ease-in-out hover:bg-gray-200;
}

.pr-navlink-toggle-btn {
  @apply flex items-center justify-end w-full px-6 py-5 transition-all duration-200 ease-in-out hover:bg-gray-200;
}

.pr-big-btn {
  @apply bg-[#3C4DE5] px-6 py-3 rounded-md text-white hover:bg-blue-600 transition-all duration-200 ease-in-out drop-shadow-lg;
}

.pr-dashboard-grid-item {
  @apply w-[92%] flex flex-col justify-center px-4 py-4 rounded-xl shadow-md bg-white;
}

.pr-dashboard-grid-item-custom {
  @apply flex flex-col items-center justify-center px-6 py-5 space-y-2 bg-white rounded-xl drop-shadow-lg;
}


.ri-content-text {
  @apply text-xs font-semibold text-gray-500;
}
.ric-bold-text {
  @apply text-base font-medium 
}

.ri-group-btn {
  @apply px-8 py-1 text-sm font-semibold duration-150 rounded;
}


.table-head {
  @apply py-2 px-4
}
